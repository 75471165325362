import React from 'react';
import {
  Box,
  Flex,
  Image,
  Text,
  Heading,
  Button,
  Center,
  Link,
  Wrap,
  WrapItem,
} from '@companydotcom/potion';
import { useTranslation } from 'react-i18next';

interface ImageProps {
  imageSrc: string;
  heading: string;
  subheading: string;
}
const ImageStep = ({ imageSrc, heading, subheading }: ImageProps) => (
  <WrapItem maxWidth={320} px={[4]} py={[5, null, 0]}>
    <Flex flexDirection="column">
      <Image
        fallback={<Box bg="white" width={320} height={165} />}
        src={imageSrc}
        alt={heading}
        sx={{ textAlign: 'center' }}
      />
      <Heading size="hs-md" mt={2}>
        {heading || ''}
      </Heading>
      <Text textStyle="md" mt={3}>
        {subheading || ''}
      </Text>
    </Flex>
  </WrapItem>
);

const data = (t: any) => [
  {
    imageSrc:
      'https://assets-dev.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-1.svg',
    heading: t('containers.darkWebScan.howItWorks.step1.header'),
    subheading: t('containers.darkWebScan.howItWorks.step1.subheader'),
  },
  {
    imageSrc:
      'https://assets-dev.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-4.svg',
    heading: t('containers.darkWebScan.howItWorks.step2.header'),
    subheading: t('containers.darkWebScan.howItWorks.step2.subheader'),
  },
  {
    imageSrc:
      'https://assets-dev.company-corp.com/dashboard_nextgen/company/images/illustrations/how-it-works-2.svg',
    heading: t('containers.darkWebScan.howItWorks.step3.header'),
    subheading: t('containers.darkWebScan.howItWorks.step3.subheader'),
  },
];

interface IHowItWorks {
  nextStep?: any;
  isMobile?: boolean;
}

export const HowItWorks = ({ nextStep }: IHowItWorks) => {
  const { t } = useTranslation();

  return (
    <Center
      className="dws-how-it-works"
      flexDirection="column"
      pt={[12, null, 10]}
      pb={[12, null, 16]}
      width="full"
      px={4}
    >
      <Box textAlign="center" mb={14}>
        <Heading as="h1" size="hs-xl">
          {t('containers.darkWebScan.howItWorks.header')}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {t('containers.darkWebScan.howItWorks.subheader')}
          <Link isExternal href="https://hacknotice.com/how-it-works-data-leak-lifecycle/" ml={[1]}>
            {t('containers.darkWebScan.howItWorks.subheader2')}
          </Link>
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('containers.darkWebScan.howItWorks.security')}
        </Text>
      </Box>
      <Wrap justify="center" align="center" my={[8, null, 12]}>
        {data?.(t)?.map((step, i) => (
          <ImageStep {...step} key={i} />
        ))}
      </Wrap>
      <Flex flexDirection={['column', null, 'row']}>
        <Button
          size="lg"
          onClick={() => {
            nextStep();
          }}
        >
          {t('containers.darkWebScan.howItWorks.next')}
        </Button>
      </Flex>
    </Center>
  );
};
